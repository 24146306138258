<!-- eslint-disable vue/no-v-html -->
<template>
  <v-card class="overflow-hidden">
    <!-- Barra de ferramentas -->
    <v-toolbar dark color="secondary" dense class="mb-5">
      <v-btn icon dark @click.native="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>{{ job.title }}</v-toolbar-title>
      <v-spacer />
    </v-toolbar>

    <!-- Campos do trabalho -->
    <v-card-text>
      <v-row justify="start" no-gutters>
        <v-col
          v-for="field in jobFields"
          :key="field.label"
          class="px-5"
          cols="12"
          lg="4"
          md="12"
        >
          <v-text-field
            v-model="job[field.prop]"
            :label="field.label"
            disabled
          />
        </v-col>
      </v-row>
    </v-card-text>

    <!-- Seções de descrição -->
    <v-card-text>
      <v-row no-gutters>
        <v-col
          v-for="section in descriptionSections"
          :key="section.title"
          cols="12"
          sm="12"
          md="6"
          lg="4"
        >
          <v-card-text class="text-h6">
            {{ section.title }}
          </v-card-text>
          <v-card-text v-html="sanitizeHtml(job[section.content])" />
        </v-col>
      </v-row>
    </v-card-text>

    <!-- Ações do cartão -->
    <v-card-actions>
      <v-row justify="center" class="pt-10">
        <v-btn
          color="secondary"
          :to="`/candidatar/${job.id}`"
          @click="loadCandidateToJob"
        >
          Candidatar-se
        </v-btn>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import DOMPurify from "dompurify";
import { mapActions } from "vuex";
import candidate from "../store/candidate.module";

export default {
  props: {
    jobProp: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },

  data() {
    return {
      job: {},
      jobFields: [
        { prop: "title", label: "Título" },
        { prop: "city", label: "Cidade" },
        { prop: "sector", label: "Setor" },
        { prop: "author", label: "Autor" },
        { prop: "createdAt", label: "Data Criação" },
      ],
      descriptionSections: [
        { title: "Descrição", content: "description" },
        { title: "Requisitos", content: "need" },
        { title: "Desejável", content: "must" },
      ],
    };
  },

  created() {
    if (this.jobProp && typeof this.jobProp === "object") {
      this.job = { ...this.jobProp };
      this.loadCandidateToJob();
    }
  },

  methods: {
    ...mapActions(candidate.namespaced ? "candidate" : "", ["emitJob"]),

    close() {
      this.$emit("close-dialog");
    },

    sanitizeHtml(html) {
      return DOMPurify.sanitize(html);
    },

    async loadCandidateToJob() {
      const jobData = {
        id: this.job.id,
        title: this.job.title,
      };
      await this.emitJob(jobData);
    },
  },
};
</script>

<style scoped>
</style>
