<template>
  <v-container fluid class="pa-0 ma-0">
    <v-row
      justify="center"
      align-self="center"
    >
      <v-col
        cols="12"
        sm="12"
        md="12"
        lg="12"
        class="pa-0 ma-0"
      >
        <carousel />
      </v-col>

      <v-col
        class="ps-8"
        xs="12"
        sm="12"
        md="10"
        lg="10"
        justify="center"
        align-self="center"
      >
        <session-title title="Vagas Internas" />
      </v-col>

      <v-col
        xs="12"
        sm="12"
        md="2"
        lg="2"
        class="ps-sm-8 ps-md-2"
        justify="center"
        align-self="center"
      >
        <chat-ti-modal-access-vue />
      </v-col>

      <v-col
        cols="12"
        sm="12"
        md="10"
        justify="center"
        align-self="center"
      >
        <v-data-table
          :headers="headers"
          :items="jobs"
          sort-by="creationDate"
          :sort-desc="true"
          :must-sort="true"
          :search="search"
        >
          <template #top>
            <v-toolbar
              class="mb-5"
              flat
            >
              <v-spacer />
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Procurar"
                single-line
                hide-details
              />
              <v-spacer />
            </v-toolbar>
          </template>
          <template #item.actions="{ item }">
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-icon
                  small
                  class="mr-2"
                  v-bind="attrs"
                  @click="viewItem(item)"
                  v-on="on"
                >
                  mdi-eye
                </v-icon>
              </template>
              <span>Visualizar</span>
            </v-tooltip>
          </template>
          <template #item.createdAt="{ item }">
            <span>
              {{ item.createdAt | dateFormatted }}
            </span>
          </template>
        </v-data-table>
        <v-dialog
          v-model="dialog"
          hide-overlay
          overflow-hidden
          max-width="1200"
          persistent
          fullscreen
        >
          <JobView
            v-if="dialog"
            :job-prop="jobToView"
            @close-dialog="closeDialog"
          />
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SessionTitle from "@/components/SessionTitle";
import Carousel from "@/components/Carousel";
import ChatTiModalAccessVue from "../../components/ModalViews/ChatTiModalAccess.vue";
import JobView from "../../components/JobView.vue";
import Jobs from "../../services/jobs-service";

export default {
  name: "JobList",
  components: {
    carousel: Carousel,
    SessionTitle,
    ChatTiModalAccessVue,
    JobView,
  },

  data() {
    return {
      loading: false,
      headers: [
        { text: 'Título', sortable: false, value: 'title' },
        { text: 'Cidade', sortable: true, value: 'city' },
        { text: 'Data de Criação', value: 'createdAt', sortable: true },
        { text: 'Ações', value: 'actions', sortable: false },
      ],
      jobs: [],
      jobToView: {},
      search: '',
      dialog: false,
    };
  },

  created() {
    this.loadActiveJobs();
  },

  methods: {
    async loadActiveJobs() {
      try {
        this.loading = true;
        const data = await Jobs.getActiveJobs();
        this.jobs = data;
      } catch (error) {
        console.error("Error fetching data:", error.message);
      } finally {
        this.loading = false;
      }
    },

    closeDialog() {
      this.dialog = false;
      this.jobToView = {};
    },

    viewItem(item) {
      this.jobToView = item;
      this.dialog = true;
    },
  },
};
</script>